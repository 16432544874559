import loadGoogleMapsApi from "load-google-maps-api";

const applyLocationButton = $("#apply-location-sorting");
let filtersCount = 0;
const locationHiddenFields = $(
  "#filter-sorting-location, #filter-sorting-lat, #filter-sorting-lng"
);

const CURRENCY_SYMBOLS = { EUR: "€", GBP: "£" };

let countryCode = "";
const euroCountries = [
  "AT",
  "BE",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PT",
  "SK",
  "SI",
  "ES",
];
const countriesWithCurrency = {
  CA: "CAD",
  AU: "AUD",
  GB: "GBP",
};

let filtersLocationChanged = false;

$(document).on("menu:closed", () => {
  const currentSorting = $("#filter-sorting").val();
  if (currentSorting !== "location") {
    $(".location--inputfields").hide();
    $("#sorting-geo-data").val("");
    $(`#${currentSorting}-check`).addClass("sorting-is-selected");
    $(".location--selector>div").removeClass("sorting-is-selected");
    applyLocationButton.prop("disabled", true);
  }
});

$(() => {
  const category = $("#category");
  const make = $("#make");
  const model = $("#model");
  const modelLabel = $("#model-input-wrapper label");
  const distanceSliderValueSpan = $("#distance-slider-value");
  const distanceSlider = $("#distance-slider");
  const distanceSliderWrapper = $("#listing-slider-wrapper");
  const filterGeoDataWrapper = $("#filter-geo-data-input-wrapper");

  distanceSlider.on("change mousemove", function onChangeDistanceSlider() {
    distanceSliderValueSpan.html(this.value);
  });

  $("#by_distance")
    .on("change", function onChangeByDistance() {
      if ($(this).prop("checked")) {
        filterGeoDataWrapper.removeClass("hidden");
        locationHiddenFields.prop("disabled", false);
        if ($("#filter-sorting-lat").val() !== "") {
          distanceSliderWrapper.removeClass("hidden");
          distanceSlider.prop("disabled", false);
        }
      } else {
        distanceSlider.prop("disabled", true);
        filterGeoDataWrapper.addClass("hidden");
        distanceSliderWrapper.addClass("hidden");
      }
    })
    .trigger("change");

  $<HTMLInputElement>("#filter-geo-data").on("change", function onChangeGeo() {
    // eslint-disable-next-line eqeqeq
    if (this.value == "") {
      distanceSliderWrapper.addClass("hidden");
      distanceSlider.prop("disabled", true);
    }
    filtersLocationChanged = true;
  });

  const priceMinInput = $("#price_min");
  const priceMaxInput = $("#price_max");

  $("#listing-filter-form").on("submit", function onSubmit(e) {
    $("#filter-search-keywords").val($(".search-bar__input").val());

    const minPrice = parseFloat(priceMinInput.val());
    const maxPrice = parseFloat(priceMaxInput.val());
    if (minPrice > 0 && maxPrice > 0 && minPrice > maxPrice) {
      e.preventDefault();
      $("#price_max").addClass("listing-filters-error");
      $("#price_error")
        .html("Max price must be greater or equal to min price")
        .slideDown();
    }

    // disable all empty fields
    $(this)
      .find<HTMLInputElement>(":input")
      .filter(function noValue() {
        return !this.value;
      })
      .attr("disabled", "disabled");
  });

  priceMinInput.on("change", hideFilterPriceError);
  priceMaxInput.on("change", hideFilterPriceError);

  $("#currency").on("change", changeCurrencySymbol).trigger("change");

  // eslint-disable-next-line eqeqeq
  if ($("#sorting-geo-data").val() != "") {
    applyLocationButton.prop("disabled", false);
  }

  $("#listing-sorting-popup>.menu--option").on("click", function onClickSort() {
    applySorting($(this).attr("data-sorting"));
  });

  $("#listing-sorting-popup>.location--selector").on("click", () => {
    $(".sorting-is-selected").removeClass("sorting-is-selected");
    $(".location--selector>div").addClass("sorting-is-selected");
    $(".location--inputfields").show();
  });

  applyLocationButton.on("click", () => {
    localizeFilters(countryCode);
    applySorting("location");
  });

  // Onload, load vehicle related filters if vehicle is selected
  if (category.val() === "vehicle") {
    toggleVehicleRelatedFields();
  }

  // Attach onchange event to listing category select
  category.on("change", toggleVehicleRelatedFields);

  make.on("change", () => {
    populateModelDropdown(model, make.val(), modelLabel);
  });

  // inserted by filter_macros.html
  const { selectedMake } = window as unknown as { selectedMake?: string };
  if ((selectedMake?.length || 0) > 0) {
    make.trigger("change");
  }

  // Attach onclick event to clear all filters span
  $("#clear-filter-span").on("click", () => {
    resetFilters();
  });

  // count applied filters
  countAppliedFilters();
  initSortingLocationAutoComplete();
  initFiltersLocationAutoComplete();

  function hideFilterPriceError() {
    $("#price_error").slideUp();
    priceMaxInput.removeClass("listing-filters-error");
  }
});

function localizeFilters(countryShortCode) {
  // eslint-disable-next-line eqeqeq
  if (countryShortCode == "") {
    return;
  }

  $("#mileage_units").val(countryShortCode === "US" ? "M" : "KM");
  $("#distance-slider-units").val(countryShortCode === "US" ? "m" : "km");
  const currency = $("#currency");
  if (euroCountries.indexOf(countryShortCode) > -1) {
    currency.val("EUR");
  } else if (countriesWithCurrency[countryShortCode] !== undefined) {
    currency.val(countriesWithCurrency[countryShortCode]);
  } else {
    currency.val("USD");
  }
}

function applySorting(sorting) {
  const searchParams = new URLSearchParams(window.location.search);

  // set sorting param in URL
  searchParams.set("sorting", sorting);

  // if we are sorting by location, also set sorting_lat, sorting_lng in URL
  if (sorting === "location") {
    searchParams.set(
      "sorting_lat",
      (document.getElementById("sorting-lat") as HTMLInputElement | undefined)
        ?.value || ""
    );
    searchParams.set(
      "sorting_lng",
      (document.getElementById("sorting-lng") as HTMLInputElement | undefined)
        ?.value || ""
    );
    searchParams.set(
      "sorting_location",
      (
        document.getElementById("sorting-location") as
          | HTMLInputElement
          | undefined
      )?.value || ""
    );
  } else {
    searchParams.delete("sorting_lat");
    searchParams.delete("sorting_lng");
    searchParams.delete("sorting_location");
  }

  // reload page with new params
  window.location.search = searchParams.toString();
}

function toggleVehicleRelatedFields() {
  // show/hide vehicle filters
  $(".vehicle-field-wrappers").toggleClass("hidden");

  // enable/disable inputs based on existing state
  const disabled = !!$("#make-field-wrapper").hasClass("hidden");
  $(".vehicle-field").prop("disabled", disabled);

  // #model input has it is own logic, based on #make
  if (disabled) {
    $("#model").prop("disabled", true);
  }
}

function populateModelDropdown(modelSelect, make, modelLabel) {
  modelSelect.html(`<option value="">Select Model</option>`);

  if (!make || !make.length) {
    modelSelect.prop("disabled", true);
    modelLabel.addClass("disabled");
    return;
  }

  modelSelect.prop("disabled", false);
  modelLabel.removeClass("disabled");

  // inserted by filter_macros.html
  const { vehicleMakesJSON } = window as unknown as {
    vehicleMakesJSON?: unknown;
  };
  const listOfVehicleModels = vehicleMakesJSON[make];
  if (!listOfVehicleModels.length) {
    return;
  }

  let addSelect;
  // inserted by filter_macros.html
  const { selectedModel } = window as unknown as { selectedModel?: string };
  listOfVehicleModels.forEach((val) => {
    // eslint-disable-next-line eqeqeq
    addSelect = selectedModel == val ? "selected" : "";
    modelSelect.append(`<option value="${val}" ${addSelect}>${val}</option>`);
  });
}

function resetFilters() {
  // reset main filters
  $(".counted-towards-filters").val("");

  $("#with_photos").attr("checked", false);
  $("#by_distance").attr("checked", false).trigger("change");

  // inserted by filter_macros.html
  const { currencyDefault } = window as unknown as { currencyDefault?: string };
  // reset other filters to default values
  $("#currency").val(currencyDefault || "");
  $("#mileage_units").val("KM");

  if (!$("#make-field-wrapper").hasClass("hidden")) {
    toggleVehicleRelatedFields();
  }
  countAppliedFilters();
}

function countAppliedFilters() {
  filtersCount = 0;
  $(".counted-towards-filters").each((_, element) => {
    if ($(element).val()) {
      filtersCount++;
    }
  });

  if ($("#with_photos").attr("checked")) {
    filtersCount++;
  }

  if ($("#by_distance").attr("checked")) {
    filtersCount++;
  }

  let filterTitle = "Filters";
  if (filtersCount > 0) {
    filterTitle += ` (${filtersCount})`;
  }

  $("#listing-filter-toggle-button .button-text").html(filterTitle);
  $("#listing-filter-header").html(filterTitle);
}

function initSortingLocationAutoComplete() {
  const autoCompleteField = document.getElementById("sorting-geo-data") as
    | HTMLInputElement
    | undefined;
  loadGoogleMapsApi({
    key: window.googleMapsAPIKey,
    libraries: ["places"],
  }).then((maps) => {
    window.autocompleteSorting = new maps.places.Autocomplete(
      autoCompleteField,
      {
        types: ["(regions)"],
      }
    );

    window.autocompleteSorting.setFields(["address_component", "geometry"]);

    const locationSelected = () => {
      const place = window.autocompleteSorting.getPlace();

      // Reset location hidden fields
      locationHiddenFields.val("");

      // Check that a location selected
      if (place === undefined || autoCompleteField.value === "") {
        applyLocationButton.prop("disabled", true);
        return;
      }

      countryCode = "";
      place.address_components.forEach((component) => {
        if (component.types.indexOf("country") >= 0) {
          countryCode = component.short_name;
        }
      });

      $("#sorting-location").val(autoCompleteField.value);
      $("#sorting-lat").val(place.geometry.location.lat());
      $("#sorting-lng").val(place.geometry.location.lng());

      applyLocationButton.prop("disabled", false);
    }; // end location selected

    window.autocompleteSorting.addListener("place_changed", locationSelected);
  }); // end loadGoogleMapApi
}

function initFiltersLocationAutoComplete() {
  const autoCompleteField = document.getElementById("filter-geo-data") as
    | HTMLInputElement
    | undefined;
  loadGoogleMapsApi({
    key: window.googleMapsAPIKey,
    libraries: ["places"],
  }).then((maps) => {
    window.autocompleteFilter = new maps.places.Autocomplete(
      autoCompleteField,
      {
        types: ["geocode"],
      }
    );

    window.autocompleteFilter.setFields(["address_component", "geometry"]);

    const locationSelected = () => {
      const place = window.autocompleteFilter.getPlace();
      // Check that a location selected
      if (
        filtersLocationChanged &&
        (place === undefined ||
          place.address_components === undefined ||
          autoCompleteField.value === "")
      ) {
        $("#listing-slider-wrapper").addClass("hidden");
        $("#distance-slider").prop("disabled", true);
        $("#filter-sorting-lat").val("");
        $("#filter-sorting-lng").val("");
        return;
      }
      $("#filter-location").val(autoCompleteField.value);
      $("#filter-lat").val(place.geometry.location.lat());
      $("#filter-lng").val(place.geometry.location.lng());
      $("#listing-slider-wrapper").removeClass("hidden");
      $("#distance-slider").prop("disabled", false);
    }; // end location selected

    window.autocompleteFilter.addListener("place_changed", locationSelected);
    const filterGeoData = $("#filter-geo-data");
    if (filterGeoData) {
      maps.event.addDomListener(filterGeoData, "blur", (e) => {
        if ($(".pac-item:hover").length === 0) {
          maps.event.trigger(e.target, "focus", {});
          maps.event.trigger(e.target, "keydown", {
            keyCode: 13,
          });
        }
      });
    }
  }); // end loadGoogleMapApi
}

function changeCurrencySymbol(e) {
  const currency = $(e.target).val();
  $("#price_min, #price_max").attr(
    "placeholder",
    `${
      currency === "EUR" || currency === "GBP"
        ? CURRENCY_SYMBOLS[currency]
        : "$"
    }0`
  );
}
